import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class SubscribeService {
  mailChimpEndpoint =
    'https://simplysubscribe.us5.list-manage.com/subscribe/post-json?u=7f87fafb220c0bd844b4a34a0&id=b93bacc8c8';
  constructor(private http: HttpClient) {}

  subscribeToList(data: {
    email: string | number | boolean;
    name: string;
    message: string;
  }) {
    const params = new HttpParams()
      .set('EMAIL', data.email)
      .set('NAME', data.name)
      .set('MESSAGE', data.message)
      .set('group[30589][1]', 'true')
      .set('b_7f87fafb220c0bd844b4a34a0_b93bacc8c8', '');
    const mailChimpUrl = `${this.mailChimpEndpoint}&${params.toString()}`;
    return this.http.jsonp(mailChimpUrl, 'c');
  }

  newSubscribe(data: {
    email: string | number | boolean;
    name: string;
    message: string;
  }) {
    const endpoint = 'https://hooks.zapier.com/hooks/catch/13272454/3wse9sh/';
    const payload = {
      email: data.email,
      name: data.name,
      message: data.message,
    };
    return this.http.post(endpoint, payload);
  }
}
