import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { SubscribeService } from './subscribe.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-doctor',
  templateUrl: './doctor.component.html',
  styleUrls: ['./doctor.component.scss'],
})
export class DoctorComponent implements OnInit {
  days: any;
  hours: any;
  minutes: any;
  seconds: any;
  myInterval: any;
  subscribeData: any = <any>{};
  subscriptionForm!: FormGroup;

  constructor(
    private subscribeService: SubscribeService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.myInterval = setInterval(() => {
      this.comingSoonTime();
    }, 0);
    this.subscriptionForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  comingSoonTime = () => {
    const endTimeParse = Date.parse('December 30, 2024 17:00:00 PDT') / 1000;
    const now = new Date();
    const nowParse = Date.parse(now.toString()) / 1000;
    const timeLeft = endTimeParse - nowParse;
    const days = Math.floor(timeLeft / 86400);
    let hours = Math.floor((timeLeft - days * 86400) / 3600);
    let minutes = Math.floor((timeLeft - days * 86400 - hours * 3600) / 60);
    let seconds = Math.floor(
      timeLeft - days * 86400 - hours * 3600 - minutes * 60
    );
    if (hours < 10) {
      hours = 0 + hours;
    }
    if (minutes < 10) {
      minutes = 0 + minutes;
    }
    if (seconds < 10) {
      seconds = 0 + seconds;
    }
    this.days = days;
    this.hours = hours;
    this.minutes = minutes;
    this.seconds = seconds;
  };

  classApplied = false;
  toggleClass() {
    this.classApplied = !this.classApplied;
  }

  classApplied2 = false;
  toggleClass2() {
    this.classApplied2 = !this.classApplied2;
  }

  subscribeMod(subscribeForm: NgForm) {
    if (subscribeForm.invalid) {
      return;
    }
    this.subscribeService.newSubscribe(this.subscribeData).subscribe(
      (res) => {
        console.log('message');
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail:
            'Simply Subscribed. We look forward to speaking with you soon.',
        });
        this.toggleClass();
      },
      (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error subscribing',
        });
      }
    );
  }

  subscribe() {
    console.log('click', this.subscriptionForm.invalid);

    if (this.subscriptionForm.invalid) {
      return;
    }
    console.log(this.subscribeData);
    this.subscribeData.email = this.subscriptionForm.value.email;
    this.subscribeService.newSubscribe(this.subscribeData).subscribe(
      (res) => {
        console.log('message');
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail:
            'Simply Subscribed. We look forward to managing your subscription',
        });
        this.toggleClass2();
      },
      (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error subscribing',
        });
      },
      () => {
        this.subscriptionForm.reset();
      }
    );
  }
}
