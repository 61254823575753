<p-toast></p-toast>
<div class="doctor">
  <div class="coming-soon-area">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="coming-soon-content">
            <div class="logo">
              <a routerLink="/doctor"
                ><img src="assets/img/sslogo1.png" alt="image"
              /></a>
            </div>
            <h4>
              Launching in Brazil and Mexico this May! <br /><br />Discover the
              power of our Subscription Library click below

              <h2>👇</h2>
            </h4>
            <!-- <h5>
              This innovative platform allows you to effortlessly advertise your
              service through our extensive partner ecosystem, reaching a
              diverse and engaged audience. Ready to elevate your visibility?
              Join us now and unlock a world of opportunities. Click here to be
              part of the subscription revolution!
            </h5> -->
            <div class="btn-box">
              <button
                class="btn btn-primary notify-btn"
                (click)="toggleClass2()"
              >
                Get Notified <i class="fas fa-bell"></i>
              </button>
              <button
                class="btn btn-light get-more-info-btn"
                (click)="toggleClass()"
              >
                Get More Info <i class="fas fa-chevron-right"></i>
              </button>
            </div>
          </div>
          <div id="timer"></div>
        </div>
      </div>
    </div>
    <div class="shape1"><img src="assets/img/circle1.png" alt="image" /></div>
    <div class="shape2"><img src="assets/img/circle2.png" alt="image" /></div>
  </div>
</div>

<!-- Sidebar Modal -->
<div class="sidebar-modal" [class.active]="classApplied">
  <div class="sidebar-modal-inner">
    <div class="about-area">
      <div class="title">
        <h2>About Us</h2>
        <p>
          This innovative platform allows you to effortlessly advertise your
          service through our extensive partner ecosystem, reaching a diverse
          and engaged audience. Ready to elevate your visibility? Join us now
          and unlock a world of opportunities. Enter your email here to be part
          of the subscription revolution!
        </p>
      </div>
      <br />
      <!-- <div class="title">
        <h2>How it Works</h2>
        <p>
          Create your account. Select your subscriptions. Create a bundle that
          fits your needs. Enter your payment informatoin. Recieve your promo
          codes to be used on the companies website on checkout.
        </p>
      </div> -->
    </div>
    <div class="contact-area">
      <div class="title">
        <h2>Contact Us</h2>
      </div>
      <div class="contact-form">
        <form
          #subscribeForm="ngForm"
          class="newsletter-form"
          (ngSubmit)="subscribeMod(subscribeForm)"
        >
          <div class="form-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Name"
              name="Name"
              [(ngModel)]="subscribeData.name"
              required
              #name="ngModel"
            />
          </div>
          <div class="form-group mb-3">
            <input
              type="email"
              class="form-control"
              placeholder="Enter email address"
              name="EMAIL"
              [(ngModel)]="subscribeData.email"
              required
              #email="ngModel"
            />
          </div>
          <div class="form-group mb-3">
            <textarea
              name="message"
              class="form-control"
              id="message"
              cols="30"
              rows="5"
              placeholder="Your Message"
              name="MESSAGE"
              [(ngModel)]="subscribeData.message"
              #message="ngModel"
            ></textarea>
          </div>
          <button type="submit">Send Message</button>
        </form>
      </div>
      <div class="contact-info">
        <div class="contact-info-content">
          <h3>Contact us by Email</h3>
          <h2>
            <a href="mailto:hello@simplysubscribe.com">{{
              "hello@simplysubscribe.com"
            }}</a>
          </h2>
          <!-- <ul class="social">
            <li>
              <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
            </li>
            <li>
              <a href="#" target="_blank"><i class="fab fa-youtube"></i></a>
            </li>
            <li>
              <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
            </li>
            <li>
              <a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a>
            </li>
            <li>
              <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
            </li>
          </ul> -->
        </div>
      </div>
    </div>
    <span class="close-btn sidebar-modal-close-btn" (click)="toggleClass()"
      ><i class="fas fa-times"></i
    ></span>
  </div>
</div>

<!-- Notify Modal -->

<div class="subscribe-modal" [class.active]="classApplied2">
  <div class="subscribe-modal-inner">
    <div class="subscribe-modal-content">
      <div class="newsletter-header">
        <div class="animation-icons">
          <span class="animate-icon"><i class="far fa-envelope"></i></span>
          <span class="animate-icon"><i class="far fa-envelope"></i></span>
          <span class="animate-icon"><i class="far fa-envelope"></i></span>
          <span class="animate-icon"><i class="far fa-envelope"></i></span>
          <span class="animate-icon"><i class="far fa-envelope"></i></span>
          <span class="animate-icon"><i class="far fa-envelope"></i></span>
          <span class="animate-icon"><i class="far fa-envelope"></i></span>
          <span class="animate-icon"><i class="far fa-envelope"></i></span>
          <span class="animate-icon"><i class="far fa-envelope"></i></span>
          <span class="animate-icon"><i class="far fa-envelope"></i></span>
          <span class="animate-icon"><i class="far fa-envelope"></i></span>
          <span class="animate-icon"><i class="far fa-envelope"></i></span>
        </div>
        <img src="assets/img/bell-icon.png" alt="image" />
      </div>
      <h2>Subscribe to Our Newsletter</h2>
      <p>
        This innovative platform allows you to effortlessly advertise your
        service through our extensive partner ecosystem, reaching a diverse and
        engaged audience. Ready to elevate your visibility? Join us now and
        unlock a world of opportunities. Enter your email here to be part of the
        subscription revolution!
      </p>
      <form
        [formGroup]="subscriptionForm"
        class="newsletter-form"
        (ngSubmit)="subscribe()"
      >
        <input
          formControlName="email"
          type="email"
          class="input-newsletter"
          placeholder="Enter email address"
          name="EMAIL"
          required
        />
        <button type="submit">Subscribe Now</button>
      </form>
      <span class="close-btn subscribe-modal-close-btn" (click)="toggleClass2()"
        ><i class="fas fa-times"></i
      ></span>
    </div>
  </div>
</div>
